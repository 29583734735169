import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IPrompt } from '../shared/interfaces/prompt.interface';
import { IPromptBase } from '../shared/interfaces/prompt.base.interface';

@Injectable({
  providedIn: 'root'
})
export class PromptService {
  baseUrl = environment.url + '/api/v1'
  private $activePrompt: BehaviorSubject<IPrompt | null> = new BehaviorSubject<IPrompt | null>(null);
  public activePrompt$: Observable<IPrompt | null> = this.$activePrompt.asObservable();
  constructor(
    private http: HttpClient
  ) { }
  updateActivePromptByID(id: string): void {
    this.getPageDataByID(id).subscribe((data: IPrompt) => {
      this.$activePrompt.next(data);
    })
  }
  getPageData(token: string | null): Observable<IPrompt> {
    const url = `${this.baseUrl}/prompt/token/${token}?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .get<IPrompt>(url)
      .pipe(catchError(this.handleError));
  }
  getPageDataByID(id: string): Observable<IPrompt> {
    const url = `${this.baseUrl}/prompt/get/${id}?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .get<IPrompt>(url)
      .pipe(catchError(this.handleError));
  }
  getPromptBase(_id: string): Observable<IPromptBase> {
    const url = `${this.baseUrl}/prompt-base/get/${_id}?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .get<IPromptBase>(url)
      .pipe(catchError(this.handleError));
  }
  updateHistory(_id: string, query: string, change: string): Observable<IPromptBase> {
    const url = `${this.baseUrl}/prompt-base/update/${_id}/history?modifiedOn=${new Date().toISOString()}`;
    return this.http
      .post<IPromptBase>(url, { query, change })
      .pipe(catchError(this.handleError));
  }
  private handleError(error: any): Observable<any> {
    console.log(error)
    return throwError(error.error || 'Server error');
  }
}

